<template>
  <b-skeleton-wrapper :loading="loading">
    <template #loading>
      <b-card no-body>
        <b-card-header>
          <div>
            <b-card-title>
              {{ $t("field.vehicleByStatus") }}
            </b-card-title>
          </div>
        </b-card-header>

        <b-card-body>
          <b-skeleton-table :columns="3" :rows="5" borderless striped>
          </b-skeleton-table>
        </b-card-body>
      </b-card>
    </template>
    <b-card no-body>
      <b-card-header>
        <div>
          <b-card-title>
            {{ $t("field.vehicleByStatus") }}
          </b-card-title>
        </div>
      </b-card-header>

      <b-card-body>
        <b-table-lite responsive :items="items" :fields="fields">
          <template #head()="scope">
            <div class="text-nowrap">{{ $t(scope.label) }}</div>
          </template>

          <template #cell(increasement)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(label)="data">
            {{ $t(`status.${data.item.label}`) }}
          </template>
          <template #cell(quantity)="data">
            {{
              $tc(`plural.vehicle`, data.item.quantity, {
                value: data.item.quantity,
              })
            }}
          </template>
        </b-table-lite>
      </b-card-body>
    </b-card>
  </b-skeleton-wrapper>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BTableLite,
  BSkeletonWrapper,
  BSkeletonTable,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BTableLite,
    BSkeletonWrapper,
    BSkeletonTable,
  },
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "increasement",
          label: "#",
          thClass: "w-50px text-center",
          tdClass: "text-center",
        },
        {
          key: "label",
          label: "field.type",
        },
        {
          key: "quantity",
          label: "field.quantity",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
    };
  },
  setup() {},
};
</script>