<template>
  <b-card no-body class="dashboard-card">
    <b-card-body class="d-flex justify-content-between align-items-center">
      <div class="truncate">
        <h6 class="mb-25 font-weight-bolder">
          {{ title }}
        </h6>
        <h1 class="font-weight-bolder" :class="`text-${color}`">
          <b-skeleton-wrapper :loading="loading">
            <template #loading>
              <b-skeleton width="150px" height="30px" />
            </template>
            {{ statistic | currency }}
          </b-skeleton-wrapper>
        </h1>
      </div>
      <b-avatar :variant="`light-${color}`" size="45">
        <feather-icon size="21" icon="DollarSignIcon" />
      </b-avatar>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BAvatar,
  BSkeletonWrapper,
  BSkeleton,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardBody,
    BAvatar,
    BSkeletonWrapper,
    BSkeleton,
  },
  props: {
    statistic: {
      type: [Number, String],
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "primary",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
