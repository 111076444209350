import { $themeColors } from '@themeConfig'
// colors
const chartColors = {
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  barColor: '#003594',
  labelColor: '#6e6b7b',
  gridLineColor: 'rgba(200, 200, 200, 0.2)',
}

export default {
  barChartOptions: {
    elements: {
      rectangle: {
        borderWidth: 1,
        borderSkipped: 'bottom',
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    responsiveAnimationDuration: 500,
    legend: {
      display: false,
      position: 'top',
      align: 'center',
      labels: {
        usePointStyle: true,
        padding: 10,
        boxWidth: 8,
      },
    },
    tooltips: {
      // Updated default tooltip UI
      shadowOffsetX: 1,
      shadowOffsetY: 1,
      shadowBlur: 8,
      shadowColor: chartColors.tooltipShadow,
      backgroundColor: $themeColors.light,
      titleFontColor: $themeColors.dark,
      bodyFontColor: $themeColors.dark,
    },
    scales: {
      xAxes: [
        {
          display: true,
          gridLines: {
            display: true,
            color: chartColors.gridLineColor,
            zeroLineColor: chartColors.gridLineColor,
          },
          scaleLabel: {
            display: false,
          },
          ticks: {
            fontColor: chartColors.labelColor,
          },
        },
      ],
      yAxes: [
        {
          display: true,
          gridLines: {
            color: chartColors.gridLineColor,
            zeroLineColor: chartColors.gridLineColor,
          },
          ticks: {
            min: 0,
            fontColor: chartColors.labelColor,
          },
        },
      ],
    },
  },
}
